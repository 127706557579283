import { createRouter, createWebHistory } from 'vue-router'


const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/post/:postId',
		name: 'Post',
		component: () => import('../views/PostView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router;
